import clsx from 'clsx';
import {MRT_Row} from 'material-react-table';
import React from 'react';
import {Chip} from '@mui/material';
import {Label} from '@lightricks/react-design-system';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import useIsMobile from '@/hooks/use-is-mobile';
import ChangeListPopover from '../ChangeListPopover';
import styles from './ListsCell.module.scss';

const MAX_LABELS = 3;
const MAX_LABELS_MOBILE = 1;
const MAX_CHARS = 18;

function ListsCell({row}: {row: MRT_Row<CreatorGroupMembership>}) {
  const [open, setOpen] = React.useState(false);
  const boxRef = React.useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();
  const maxLabels = isMobile ? MAX_LABELS_MOBILE : MAX_LABELS;
  const maxChars = MAX_CHARS;

  const {labels} = row.original;
  const displayLabels = Array.from(labels).slice(0, maxLabels);

  const getTotalLength = (_labels: CreatorGroupLabel[]) => {
    const paddingCompensation = (_labels.length - 1) * 2;
    const sumLengths = (sum: number, label: CreatorGroupLabel) =>
      sum + label.name.length;

    return _labels.reduce(sumLengths, 0) + paddingCompensation;
  };

  while (getTotalLength(displayLabels) > maxChars && displayLabels.length > 1) {
    displayLabels.pop();
  }

  const truncatedNumberOfLabels = labels.length - displayLabels.length;
  const trunctedButtonLabel =
    truncatedNumberOfLabels > 99 ? '99+' : `+${truncatedNumberOfLabels}`;

  return (
    <div className={styles.listsCell}>
      <div
        className={styles.container}
        ref={boxRef}
        onClick={() => {
          setOpen(true);
        }}
      >
        {displayLabels.map((label) => (
          <ListRowChip key={label.id} label={label.name} />
        ))}
        {truncatedNumberOfLabels > 0 && (
          <ListRowChip label={trunctedButtonLabel} />
        )}
      </div>
      <ChangeListPopover
        membership={row.original}
        anchorEl={boxRef.current}
        onClose={() => setOpen(false)}
        open={open}
      />
    </div>
  );
}

type ListRowChipProps = {
  label?: string;
  icon?: React.ReactElement;
};

function ListRowChip({label, icon}: ListRowChipProps) {
  const labelProp = (
    <Label size="sm" sx={{fontWeight: 500}}>
      {label}
    </Label>
  );

  const iconOnly = !label;

  return (
    <Chip
      label={labelProp}
      icon={icon}
      variant="outlined"
      classes={{
        root: clsx(styles.chip, iconOnly && styles.chip__iconOnly),
        icon: styles.chipIcon,
        label: styles.chipLabel,
      }}
    />
  );
}

export default ListsCell;
