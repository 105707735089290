import React from 'react';
import {Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import FlashMessage from '@/components/flash-message';
import {FlashMessageOptions} from '@/components/flash-messages-service/flashMessageTypes';
import {flashMessagesActions} from '@/stores/flashMessagesStore';

function raiseFlashMessage(options: FlashMessageOptions) {
  const flashMessage = flashMessagesActions.addFlashMessage(options);
  return () => flashMessagesActions.removeFlashMessage(flashMessage);
}

export function raiseFlashMessageError(
  {
    title,
    subtitle,
  }: {
    title: string;
    subtitle: string;
  } = {
    title: translate(`errors.something-went-wrong`),
    subtitle: translate(`errors.please-try-again-or-come-back-later`),
  }
) {
  raiseFlashMessage({
    status: 'error',
    icon: <Icon size="large" appearance="danger" name="Navigation-Attention" />,
    mode: 'light',
    message: <FlashMessage.Banner title={title} subtitle={subtitle} />,
  });
}

export default raiseFlashMessage;
