import api from '../index';

type ExternalProfilesUpdaterArgs = {
  brand_invite: {
    brandId: string;
    profileId: string;
  };
};

const externalProfilesUpdaters = {
  brand_invite: async (args: ExternalProfilesUpdaterArgs['brand_invite']) => {
    const {brandId, profileId} = args;

    return api.put(
      `/external_profiles/${profileId}/brand_invite`,
      {
        brand_id: brandId,
      },
      {}
    );
  },
};

export default externalProfilesUpdaters;
