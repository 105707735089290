import {produce} from 'immer';
import queryClient from '@/utils/reactQueryClient';
import {CreatorSafetyReport} from '@/types/creatorSafetyReport';
import {queryKey as vettingReportQueryKey} from '@/hooks/queries/use-vetting-report-query/useVettingReportQuery';

interface UpdateVettingReportInvitationEmailSentParams {
  creatorId: string;
  reportId: string;
}

// eslint-disable-next-line import/prefer-default-export
export const updateVettingReportInvitationEmailSent = ({
  creatorId,
  reportId,
}: UpdateVettingReportInvitationEmailSentParams) => {
  const queryKey = [vettingReportQueryKey, creatorId, reportId];
  const previousData = queryClient.getQueryData(queryKey);

  queryClient.setQueryData(
    queryKey,
    (old: {data: CreatorSafetyReport} | undefined) => {
      if (!old) {
        return old;
      }
      return produce(old, (draft) => {
        if (draft) {
          draft.data.invitationEmailSent = true;
        }
      });
    }
  );

  return previousData;
};
