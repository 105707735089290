import React from 'react';
import {Body, Headline, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Link from '@/components/link';
import styles from './EmptyState.module.scss';

function EmptyState() {
  return (
    <div className={styles.emptyScreen}>
      <Icon size="large" appearance="black" name="Navigation-Photos-Options" />
      <Headline size="xl" className={styles.overlayActionLink}>
        {translate("Build your brand's content library")}
      </Headline>
      <Body size="xl" className={styles.overlayActionLink}>
        {translate("What's a library without any content?")}
      </Body>
      <Body size="lg" className={styles.overlayActionLink}>
        {translate(
          'Filter, search, and download approved content from across your campaigns.'
        )}
      </Body>
      <TutorialLink />
    </div>
  );
}

function TutorialLink() {
  return (
    <Link
      key="show-me-tutorial"
      to={translate('content-library-tutorial-link')}
      target="_blank"
    >
      <div className={styles.tutorialLink}>
        <div className={styles.iconContainer}>
          <Icon
            size="small"
            style={{
              width: 12,
              height: 12,
            }}
            appearance="black"
            name="Features-Play"
          />
        </div>
        <Body size="md" className={styles.campaignLink}>
          {translate('Show me a tutorial')}
        </Body>
      </div>
    </Link>
  );
}

export default EmptyState;
