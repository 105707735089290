import React, {useEffect, useMemo} from 'react';
import {Icon} from '@lightricks/react-design-system';
import raiseFlashMessage from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import {HTTP_STATUS} from '@/config/constants';
import {ErrorResponse} from '@/types/errorResponse';
import PoppaysLoader from '@/components/PoppaysLoader';
import CreatorVettingReport from '@/components/creator-vetting-report';
import ProfileVettingEmptyState from '@/components/creator-vetting-report/components/profile-vetting-empty-state';
import FlashMessage from '@/components/flash-message';
import Page from '@/components/page';
import useVettingReportQuery from '@/hooks/queries/use-vetting-report-query';
import useBrandId from '@/hooks/use-brand-id';
import useParams from '@/hooks/use-params';
import styles from './VettingReport.module.scss';

function VettingReport() {
  const {creatorId, reportId} = useParams();
  const brandId = useBrandId();
  const {
    data: vettingReport,
    status,
    isLoading,
    isError,
    error,
  } = useVettingReportQuery({
    brandId,
    creatorId,
    reportId,
  });

  const isOptedOut = useMemo(() => {
    if (error) {
      return (error as ErrorResponse)?.status === HTTP_STATUS.GONE;
    }
    return false;
  }, [error]);

  useEffect(() => {
    if (isError) {
      if (isOptedOut) {
        return;
      }
      raiseFlashMessage({
        status: 'error',
        icon: (
          <Icon size="large" appearance="danger" name="Navigation-Attention" />
        ),
        mode: 'light',
        message: (
          <FlashMessage.Banner
            title={translate(`errors.page-did-not-load`)}
            subtitle={translate(`errors.try-refreshing-or-check-back-later`)}
          />
        ),
      });
    }
  }, [isError, isOptedOut]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.loadingWrapper}>
          <PoppaysLoader absolute />
        </div>
      );
    }

    if (isError) {
      if (isOptedOut) {
        return (
          <div className={styles.emptyWrapper}>
            <ProfileVettingEmptyState
              title={translate(
                'This creator enabled privacy settings that don’t allow vetting.'
              )}
            />
          </div>
        );
      }
      return null;
    }

    if (Number(status) === HTTP_STATUS.NO_CONTENT || !vettingReport) {
      return (
        <div className={styles.emptyWrapper}>
          <ProfileVettingEmptyState
            title={translate('Report creation in progress')}
            subtitle={translate('Please come back later.')}
          />
        </div>
      );
    }

    return (
      <CreatorVettingReport vettingReport={vettingReport} showProfileInfo />
    );
  };

  return (
    <Page
      id="vetting-report"
      className={styles.vettingReportContainer}
      childrenClassName={styles.vettingReportWrapper}
    >
      {renderContent()}
    </Page>
  );
}

export default VettingReport;
