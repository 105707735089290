import {useFlags} from 'launchdarkly-react-client-sdk';
import {uniq} from 'lodash';
import React, {useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {
  Avatar,
  Body,
  Button,
  Icon,
  Input,
  Label,
  Modal,
  designSystemToken,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import emailValidator from '@/utils/validators/email';
import {VettableCreator} from '@/types/vettableCreatorsResponse';
import SocialNetworkIcon from '@/components/social-network-icon';
import styles from './CreatorSearchResult.module.scss';
import SocialIconBadge from './SocialIconBadge';

const providerPriority: Record<string, number> = {
  instagram: 1,
  tiktok: 2,
  youtube: 3,
  facebook: 4,
  other: 5,
};

type CreatorSearchResultProps = {
  creator: VettableCreator;
  avatarSrc: string | undefined;
  isAdded: boolean;
  onClickAdd: (creator: VettableCreator) => Promise<void>;
  isOffPlatform: boolean;
  searchNetwork: string;
};

function CreatorSearchResult(props: CreatorSearchResultProps) {
  const {
    creator,
    avatarSrc,
    isAdded,
    onClickAdd,
    isOffPlatform,
    searchNetwork,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const onClick = async () => {
    setIsLoading(true);
    await onClickAdd(creator);
    setIsLoading(false);
  };
  const {brightDataPoc} = useFlags();
  const hasMissingEmail = creator.email === null;

  const onPlatformAccounts = creator.accounts?.sort((a, b) => {
    return providerPriority[a.platform] - providerPriority[b.platform];
  });

  const platform = useMemo(
    () =>
      onPlatformAccounts
        ? onPlatformAccounts.find((a) => a.platform.includes(searchNetwork))
            ?.platform
        : creator.provider,
    [onPlatformAccounts, creator, searchNetwork]
  );

  const relatedProviders = useMemo(
    () =>
      onPlatformAccounts
        ? onPlatformAccounts
            ?.map((a) => a.platform)
            .filter((onPlatform) => !onPlatform.includes(searchNetwork))
        : creator.relatedAccounts?.map((a) => a.provider),
    [onPlatformAccounts, creator, searchNetwork]
  );

  const onSubmitEmailModal = (value: string) => {
    onClickAdd({...creator, email: value, inviteOnly: true});
  };

  const renderConditionalComponent = () => {
    if (creator.isMinor) {
      return (
        <WarningComponent
          warning={translate(
            "This creator can't be vetted due to an age restriction or unverified age."
          )}
        />
      );
    }
    if (creator.optedOut) {
      return (
        <WarningComponent
          warning={translate(
            'This creator enabled privacy settings that don’t allow vetting.'
          )}
        />
      );
    }
    if (hasMissingEmail && brightDataPoc && !isAdded) {
      return <EmailNotConnected onSubmit={onSubmitEmailModal} />;
    }
    return null;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.avatarContainer}>
          <Avatar
            size="lg"
            type="picture"
            background="none"
            src={avatarSrc}
            className={styles.avatar}
          />
          <SocialIconBadge platform={platform} className={styles.socialIcon} />
        </div>
        <div className={styles.creatorInfo}>
          <Label size="lg">
            @{creator.username ?? creator.accounts?.[0].username ?? ''}
          </Label>
          <div className={styles.creatorDetails}>
            <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
              {creator.fullName}
            </Body>
            {relatedProviders && relatedProviders.length > 0 && (
              <AlsoFoundOn relatedProviders={relatedProviders} />
            )}
          </div>
        </div>
        {isAdded ? (
          <AddButton isAdded label={translate('added')} />
        ) : (
          <AddButton
            label={translate('add')}
            onClick={onClick}
            isLoading={isLoading}
            disabled={
              false // DEBUG ONLY REMOVE BEFORE MERGE
              // isLoading ||
              // hasMissingEmail ||
              // !!creator.optedOut ||
              // !!creator.isMinor ||
              // (isOffPlatform && !brightDataPoc)
            }
          />
        )}
      </div>
      {renderConditionalComponent()}
    </>
  );
}

type AddButtonProps = {
  label: string;
  onClick?: (e: React.UIEvent) => void;
  isAdded?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
};

function AddButton(props: AddButtonProps) {
  const {label, onClick, isAdded = false, disabled, isLoading} = props;

  const color = isAdded ? 'brand' : 'neutral-secondary';
  const startIcon = isAdded ? (
    <Icon name="Actions-Accept" size="small" appearance="brand" />
  ) : undefined;

  return (
    <Button
      appearance={isAdded ? 'brand' : 'neutral'}
      mode={isAdded ? 'plain' : 'filled'}
      size="small"
      onClick={onClick}
      disabled={disabled || isAdded}
      isLoading={isLoading}
      startIcon={startIcon}
    >
      <Label size="md" color={designSystemToken(`semantic.fg.${color}`)}>
        {label}
      </Label>
    </Button>
  );
}

function AlsoFoundOn({
  relatedProviders,
}: {
  relatedProviders: Array<VettableCreator['provider']>;
}) {
  const visibleProviders: string[] = ['tiktok', 'instagram'];
  const renderedProviders = uniq(relatedProviders).filter((provider) => {
    return visibleProviders.find((visibleProvider) =>
      provider?.includes(visibleProvider)
    );
  });

  return (
    <Body
      size="sm"
      color={designSystemToken('semantic.fg.secondary')}
      sx={{display: 'flex', alignItems: 'flex-end', gap: '2px'}}
    >
      {translate('also-on')}
      <Box sx={{display: 'flex', gap: '2px'}}>
        {renderedProviders.map((provider) => (
          <SocialNetworkIcon
            key={provider}
            name={provider}
            width={18}
            height={18}
            color={designSystemToken('semantic.fg.secondary')}
          />
        ))}
      </Box>
    </Body>
  );
}

type EmailNotConnectedProps = {
  onSubmit: (val: string) => void;
};

function EmailNotConnected({onSubmit}: EmailNotConnectedProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  const [value, setValue] = React.useState('');

  const handlePrimaryButtonClick = () => {
    onSubmit(value);
    setIsOpen(false);
  };

  return (
    <Box className={styles.warning}>
      <Body size="md">
        {translate("This creator's email is not connected")},
      </Body>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        sx={{p: 0, pl: '4px'}}
        appearance="neutral"
        mode="plain"
        size="small"
        className={styles.addEmailButton}
      >
        <Label size="md" underline>
          {translate('add email to continue')}
        </Label>
      </Button>
      <Modal
        open={isOpen}
        showCloseButton
        size="large"
        header={translate("Add creator's email")}
        footerAlignment="right"
        textAlignment="left"
        primaryButtonText={translate('invite')}
        primaryButtonProps={{disabled: !isSubmitEnabled}}
        handlePrimaryButtonClick={handlePrimaryButtonClick}
        handleClose={() => setIsOpen(false)}
      >
        <div>
          <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
            {translate(
              'The creator will receive an email mentioning your brand and inviting them to join Popular Pays and continue the process.'
            )}
          </Body>
          <Input
            placeholder={translate('email-placeholder')}
            inputProps={{value}}
            fullWidth
            className={styles.emailModalInput}
            validatorOptions={{
              onChangeText: (val: string) => setValue(val),
              onValidation: (isValid: boolean) => setIsSubmitEnabled(isValid),
              validators: [
                {
                  validator: emailValidator,
                },
              ],
            }}
          />
        </div>
      </Modal>
    </Box>
  );
}

function WarningComponent({warning}: {warning: string}) {
  return (
    <Box className={styles.warning}>
      <Body size="md">{warning}</Body>
    </Box>
  );
}

export default CreatorSearchResult;
