import {pick} from 'lodash';
import React, {useCallback, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Asset} from '@/types/asset';
import EmptyState from '@/views/content-library/components/empty-state';
import FiltersBar from '@/views/content-library/components/filters-bar/FiltersBar';
import useContentLibraryVirtualizedGridProps from '@/views/content-library/hooks/use-content-library-virtualized-grid-props/useContentLibraryVirtualizedGridProps';
import PoppaysLoader from '@/components/PoppaysLoader';
import AssetCard from '@/components/asset-card';
import Conditional from '@/components/conditional';
import Page from '@/components/page';
import VirtualizedGrid from '@/components/virtualized-grid';
import useAssetsQuery from '@/hooks/queries/use-assets-query';
import useBrandId from '@/hooks/use-brand-id';
import useEmberIframeStyleStore from '@/stores/emberIframeStyleStore';
import styles from './ContentLibrary.module.scss';

const GRID_SCROLL_TOP_KEY = 'contentLibraryGridScrollTop';

export type AssetsFilters = {
  kind?: 'photo' | 'video' | 'blog';
  keywords?: string;
  q?: string; // this is the creator query
  network?: string;
  campaign?: string; // this should be the campaign name
};
const relevantContentFilters = ['kind', 'keywords', 'q', 'network', 'campaign'];

function getInitialFilters(searchParams: URLSearchParams): AssetsFilters {
  return pick(
    Object.fromEntries(searchParams),
    relevantContentFilters
  ) as AssetsFilters;
}

function assetsFiltersToQueryParams(assetFilters: AssetsFilters): {
  [key: string]: string | number;
} {
  const result: {[key: string]: string | number} = {};

  Object.entries(assetFilters).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      result[key] = value;
    }
  });
  return result;
}

function ContentLibrary() {
  const [searchParams, setSearchParams] = useSearchParams();
  const brandId = useBrandId();

  const [assetsFilters, updateAssetsFilters] = useState<AssetsFilters>(
    getInitialFilters(searchParams)
  );
  const onFilterChange = (filterName: string, filterValue: any) => {
    updateAssetsFilters({
      ...assetsFilters,
      [filterName]: filterValue,
    });
    if (filterValue) {
      searchParams.set(filterName, filterValue);
    } else {
      searchParams.delete(filterName);
    }
    setSearchParams(searchParams);
  };
  const filtersQueryParams = assetsFiltersToQueryParams(assetsFilters);
  const {data, fetchNextPage, isFetchingNextPage, isLoading, isFetching} =
    useAssetsQuery({
      brandId,
      queryParams: filtersQueryParams,
    });
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const gridScrollRef = useRef<HTMLDivElement>(null);
  const {sidebarWidth} = useEmberIframeStyleStore();
  const isEmpty = data.length === 0 && !isLoading && !isFetching;
  const renderAssetCard = useCallback((asset: Asset) => {
    return (
      <AssetCard
        key={asset.id}
        id={asset.id}
        downloadUrl={asset.downloadUrl}
        isAiContent={asset.isAiContent}
        kind={asset.kind}
        thumbnailUrl={asset.thumbnailUrl}
        url={asset.url}
        profile={asset.profile}
        campaign={asset.campaign}
      />
    );
  }, []);
  const contentLibraryVirtualizedGridProps =
    useContentLibraryVirtualizedGridProps(gridContainerRef, [sidebarWidth]);
  return (
    <div className={styles.column}>
      <FiltersBar
        initialFilters={assetsFilters}
        onFilterChange={onFilterChange}
      />
      <Page id="content-library" className={styles.container}>
        {isLoading ? <PoppaysLoader className={styles.loader} /> : null}
        <Conditional condition={!isEmpty} fallback={<EmptyState />}>
          <div ref={gridContainerRef}>
            <VirtualizedGrid
              {...contentLibraryVirtualizedGridProps}
              containerClassName={`${styles.gridContainer} ${
                isLoading || isFetching ? styles.loading : ``
              }`}
              data={data}
              onEndReached={fetchNextPage}
              isInitialLoading={isLoading}
              showLoader={isFetchingNextPage}
              skeletonsAmount={contentLibraryVirtualizedGridProps.columns * 2}
              renderItemSkeleton={(index: number) => <div key={index} />}
              gridScrollRef={gridScrollRef}
              gridScrollTopKey={GRID_SCROLL_TOP_KEY}
              renderItem={renderAssetCard}
            />
          </div>
        </Conditional>
      </Page>
    </div>
  );
}

export default ContentLibrary;
