import {useMutation} from '@tanstack/react-query';
import externalProfilesUpdaters from '@/api/updaters/externalProfiles';

type InviteExternalProfileArgs = {
  onSuccess?: (data: unknown) => void;
  onMutate?: (data: unknown) => void;
  onError?: (error: unknown, variables: unknown, context: unknown) => void;
  onSettled?: (data: unknown, error: unknown) => void;
};

function useInviteExternalProfile(args: InviteExternalProfileArgs) {
  return useMutation({
    mutationKey: ['externalProfile', 'brand_invite'],
    mutationFn: externalProfilesUpdaters.brand_invite,
    ...args,
  });
}

export default useInviteExternalProfile;
