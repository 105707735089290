import React, {useMemo, useState} from 'react';
import {Menu, MenuItem} from '@mui/material';
import {MenuProps} from '@mui/material/Menu/Menu';
import {MenuItemOwnProps} from '@mui/material/MenuItem/MenuItem';
import {Button, designSystemToken, Icon} from '@lightricks/react-design-system';
import styles from './DropdownButton.module.scss';

export interface Option<T = string> {
  id: T;
  label: string;
  disabled?: boolean;
}

export interface DropdownButtonProps<T extends string> {
  testID?: string;
  buttonClassName?: string;
  label: string | React.ReactNode;
  appearance?: 'brand' | 'neutral' | 'danger' | 'overlay';
  mode?: 'filled' | 'tinted' | 'plain' | 'elevated';
  size?: 'small' | 'medium' | 'large';
  caretColor?: string;
  options: Option<T>[];
  selectedOptionIds?: string[];
  onChange: (option: Option<T>) => void;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  renderMenuTitle?: () => React.ReactNode;
  renderMenuItem?: (option: Option<T>, isSelected: boolean) => React.ReactNode;
  menuWidth?: number;
  menuProps?: Partial<MenuProps>;
  menuItemProps?: Partial<MenuItemOwnProps>;
  onClick?: () => void;
  onClose?: () => void;
  closeAfterSelect?: boolean;
}

function DropdownButton<T extends string>(props: DropdownButtonProps<T>) {
  const {
    testID = 'dropdown-button',
    buttonClassName,
    label,
    appearance = 'neutral',
    mode = 'plain',
    size = 'small',
    caretColor = designSystemToken('semantic.fg.primary'),
    options,
    selectedOptionIds = [],
    onChange,
    disabled,
    startIcon,
    renderMenuTitle,
    renderMenuItem,
    menuWidth,
    menuProps = {},
    menuItemProps = {},
    onClick,
    onClose,
    closeAfterSelect = true,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    onClick?.();
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    onClose?.();
    setAnchorEl(null);
  };

  const buttonStyle = useMemo(() => {
    let borderColor = designSystemToken('semantic.bg.neutral-inverse');
    if (disabled) {
      borderColor = designSystemToken('semantic.fg.disabled');
    } else if (anchorEl) {
      borderColor = designSystemToken('semantic.fg.primary');
    }

    return {
      border: `1px solid ${borderColor}`,
    };
  }, [disabled, anchorEl]);

  const caretStyle = useMemo(() => {
    if (disabled) {
      return designSystemToken('semantic.fg.disabled');
    }

    return caretColor;
  }, [disabled, caretColor]);

  const renderMenu = () => (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleOnClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '24px',
          paddingInline: '8px',
          minWidth: '150px',
          width: menuWidth || 'unset',
        },
        '& .MuiMenu-list': {
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        },
      }}
      {...menuProps}
    >
      {renderMenuTitle?.()}
      {options.map((option) => (
        <MenuItem
          data-testid={`${testID}--option--${option.id}`}
          key={option.id}
          onClick={(event) => {
            event.stopPropagation();
            onChange(option);
            if (closeAfterSelect) {
              setAnchorEl(null);
            }
          }}
          disabled={option.disabled}
          selected={selectedOptionIds?.includes(option.id)}
          sx={{
            'display': 'flex',
            'padding': '14px 16px',
            'borderRadius': '8px',
            '&:hover': {
              background: designSystemToken('semantic.bg.tertiary'),
            },
          }}
          {...menuItemProps}
        >
          {renderMenuItem?.(
            option,
            selectedOptionIds?.includes(option.id) || false
          ) || option.label}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <Button
        testID={testID}
        className={buttonClassName}
        size={size}
        mode={mode}
        appearance={appearance}
        disabled={disabled}
        onClick={handleOnClick}
        sx={buttonStyle}
        startIcon={startIcon}
        endIcon={
          <Icon
            className={anchorEl ? styles.iconOpen : ''}
            size={size}
            appearance="neutral"
            name="Actions-Arrow-Down-Small"
            color={caretStyle}
          />
        }
      >
        {label}
      </Button>
      {renderMenu()}
    </>
  );
}

export default DropdownButton;
