export const ALL_NETWORKS_ID = 'all';
export const NONE_NETWORKS_ID = 'none';
export const NETWORK_PROVIDERS = [
  {
    id: ALL_NETWORKS_ID,
    labelLocaleKey: 'all',
    value: null,
  },
  {
    id: NONE_NETWORKS_ID,
    labelLocaleKey: 'None',
    value: 'content_creation',
  },
  {
    id: 'instagram',
    labelLocaleKey: 'Instagram',
    value: 'instagram,instagram_business',
  },
  {
    id: 'tiktok',
    labelLocaleKey: 'TikTok',
    value: 'tiktok,tiktok_v2,tiktok_creator_marketplace',
  },
  {
    id: 'youtube',
    labelLocaleKey: 'YouTube',
    value: 'youtube',
  },
  {
    id: 'facebook',
    labelLocaleKey: 'Facebook',
    value: 'facebook',
  },
  {
    id: 'pinterest',
    labelLocaleKey: 'Pinterest',
    value: 'pinterest,pinterest_organic',
  },
];
