import React, {useMemo} from 'react';
import {Body} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {AssetsFilters} from '@/views/content-library/ContentLibrary';
import {
  ALL_NETWORKS_ID,
  NETWORK_PROVIDERS,
} from '@/views/content-library/components/filters-bar/constants';
import Autocomplete from '@/components/autocomplete';
import SearchInput from '@/components/search-input';
import {Option} from '@/components/select/SelectProps';
import SingleSelect from '@/components/select/single-select';
import useBrandCampaignsQuery from '@/hooks/queries/use-brand-campaigns-query';
import useBrandId from '@/hooks/use-brand-id';
import styles from './FiltersBar.module.scss';

export type FiltersBarProps = {
  initialFilters: AssetsFilters;
  onFilterChange: (filterName: string, filterValue: any) => void;
};
const Kind = {
  ALL: {id: 'all', title: 'All'},
  PHOTO: {id: 'photo', title: 'Photo'},
  VIDEO: {id: 'video', title: 'Video'},
} as const;

function mapKindToOptions(): Option[] {
  return Object.values(Kind).map(
    (kind): Option => ({
      id: kind.id, // Use the enum value as the id
      label: translate(kind.title),
      key: kind.id,
    })
  );
}

function FiltersBar({initialFilters, onFilterChange}: FiltersBarProps) {
  const brandId = useBrandId();
  const {brandCampaigns} = useBrandCampaignsQuery({
    brandId,
    refetchOnWindowFocus: false,
  });
  const brandCampaignOptions = useMemo(() => {
    return brandCampaigns.map(
      (campaign): Option => ({
        id: campaign.id,
        label: campaign.name,
        key: campaign.id,
      })
    );
  }, [brandCampaigns]);

  const networkOptions = NETWORK_PROVIDERS.map(
    (socialNetwork): Option => ({
      id: socialNetwork.id,
      label: translate(socialNetwork.labelLocaleKey),
      key: socialNetwork.id,
    })
  );

  const kindOptions = mapKindToOptions();
  const allCampaigns: Option = {
    id: 'all-campaigns',
    label: translate('All Campaigns'),
    key: 'all-campaigns',
  };
  const campaignOptions = [allCampaigns, ...brandCampaignOptions];
  const getCampaignNameFromId = (id: string) => {
    if (id === allCampaigns.id) {
      return undefined;
    }
    return brandCampaigns.find((campaign) => campaign.id === id)?.name;
  };
  const getKindFromId = (kindId: string): 'photo' | 'video' | undefined => {
    switch (kindId) {
      case Kind.ALL.id:
        return undefined;
      case Kind.PHOTO.id:
        return kindId;
      case Kind.VIDEO.id:
        return kindId;
      default:
        return undefined;
    }
  };
  const getNetworkFromId = (id: string) => {
    const networkProvider = NETWORK_PROVIDERS.find((prov) => prov.id === id);
    return networkProvider?.value;
  };
  const initialNetworkId = NETWORK_PROVIDERS.find(
    (network) => network?.value === initialFilters?.network
  )?.id;

  return (
    <div className={styles.filtersTopBar}>
      <FilterCategorySelect
        categoryName={translate(`Content Type`)}
        itemOptions={kindOptions}
        selectedId={initialFilters.kind || Kind.ALL.id}
        onSelect={(value) => {
          onFilterChange('kind', getKindFromId(value));
        }}
      />
      <FilterCategoryFreeText
        categoryName={translate(`Keywords`)}
        initialValue={initialFilters.keywords || ''}
        placeholder={translate('e.g.-food, fashion')}
        onSearch={(value) => onFilterChange('keywords', value)}
      />
      <FilterCategoryFreeText
        categoryName={translate(`Creator`)}
        initialValue={initialFilters.q || ''}
        placeholder={translate('name or handle..')}
        onSearch={(value) => onFilterChange('q', value)}
      />
      <FilterCategorySelect
        categoryName={translate(`Network`)}
        itemOptions={networkOptions}
        selectedId={initialNetworkId || ALL_NETWORKS_ID}
        onSelect={(value) => onFilterChange('network', getNetworkFromId(value))}
      />
      <FilterCategoryAutocomplete
        categoryName={translate(`Campaign`)}
        itemOptions={campaignOptions}
        onSelect={(event, selectedOption) => {
          onFilterChange('campaign', getCampaignNameFromId(selectedOption?.id));
        }}
        selectedId={initialFilters.campaign || allCampaigns.id}
      />
    </div>
  );
}

export type FilterCategorySelectProps = {
  categoryName: string;
  itemOptions: Option[];
  selectedId: string;
  onSelect?: (value: string) => void;
};
function FilterCategorySelect({
  categoryName,
  itemOptions,
  selectedId,
  onSelect,
}: FilterCategorySelectProps) {
  return (
    <div className={styles.singleFilter}>
      <Body size="md" style={{fontWeight: 'bold'}}>
        {categoryName}
      </Body>
      <SingleSelect
        options={itemOptions}
        containerClassName={styles.selectorContainer}
        selectedValueIds={[selectedId]}
        variant="default"
        onChange={onSelect}
      />
    </div>
  );
}

export type FilterCategoryFreeTextProps = {
  categoryName: string;
  initialValue: string;
  placeholder: string;
  onSearch: (value: string) => void;
};
function FilterCategoryFreeText({
  categoryName,
  initialValue,
  placeholder,
  onSearch,
}: FilterCategoryFreeTextProps) {
  return (
    <div className={styles.singleFilter}>
      <Body size="md" style={{fontWeight: 'bold'}}>
        {categoryName}
      </Body>
      <SearchInput
        value={initialValue}
        onSubmit={onSearch}
        submitOnBlur
        hideSearchIcon
        placeholder={placeholder}
        className={styles.searchInputContainer}
      />
    </div>
  );
}

export type FilterCategoryAutocompleteProps = {
  categoryName: string;
  itemOptions: Option[];
  onSelect?: (event: any, selectedOption: any) => void;
  selectedId: string;
};
function FilterCategoryAutocomplete({
  categoryName,
  itemOptions,
  onSelect,
  selectedId,
}: FilterCategoryAutocompleteProps) {
  return (
    <div className={styles.singleFilter}>
      <Body size="md" style={{fontWeight: 'bold'}}>
        {categoryName}
      </Body>
      <Autocomplete
        containerClassName={styles.autocompleteContainer}
        options={itemOptions}
        className={styles.autocomplete}
        onChange={onSelect}
        selectedOptionId={selectedId}
        overrideDefaultPopperStyle
      />
    </div>
  );
}
export default FiltersBar;
