import {useEffect, useState} from 'react';
import {STALE_TIME} from '@/utils/reactQueryClient';
import {HTTP_STATUS} from '@/config/constants';
import {CreatorSafetyReport} from '@/types/creatorSafetyReport';
import {ErrorResponse} from '@/types/errorResponse';
import vettingFetchers from '@/api/fetchers/vetting';
import useDataQuery from '@/hooks/use-data-query';

export const queryKey = 'vettingReport';

function useVettingReportQuery({
  brandId,
  creatorId,
  reportId,
}: {
  brandId: string;
  creatorId: string;
  reportId: string;
}) {
  const [dynamicQueryTime, setDynamicQueryTime] = useState(STALE_TIME);

  const response = useDataQuery<CreatorSafetyReport>({
    queryKey: [queryKey, creatorId, reportId],
    queryFn: vettingFetchers.report,
    enabled: !!brandId && !!creatorId && !!reportId,
    refetchOnWindowFocus: dynamicQueryTime !== Infinity,
    cacheTime: dynamicQueryTime,
    staleTime: dynamicQueryTime,
    retry: 0,
    meta: {
      brandId,
      creatorId,
      reportId,
    },
  });

  useEffect(() => {
    if (Number(response.status) === HTTP_STATUS.OK) {
      setDynamicQueryTime(Infinity);
    }
    if ((response.error as ErrorResponse)?.status === HTTP_STATUS.GONE) {
      setDynamicQueryTime(Infinity);
    }
  }, [response.status]);

  return response;
}

export default useVettingReportQuery;
