import api from '../index';

type ExternalRegistrationUpdaterArgs = {
  create: {
    brandId: string;
    handle: string;
    network: string;
    networkUrl?: string;
    email: string;
    displayName: string;
    profileImageUrl?: string;
    modashId: string;
    relatedAccounts: Array<{
      network: string;
      networkUrl: string;
    }>;
  };
};

const externalRegistrationUpdaters = {
  create: async (args: ExternalRegistrationUpdaterArgs['create']) => {
    const {
      brandId,
      handle,
      network,
      networkUrl,
      email,
      displayName,
      profileImageUrl,
      modashId,
      relatedAccounts,
    } = args;

    return api.post<{profileId: string}>(
      `/external_registrations`,
      {
        handle,
        network,
        email,
        networkUrl,
        display_name: displayName,
        profile_image_url: profileImageUrl,
        modash_id: modashId,
        related_accounts: relatedAccounts,
      },
      {
        params: {
          brand_id: brandId,
        },
      }
    );
  },
};

export default externalRegistrationUpdaters;
