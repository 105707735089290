import React, {useMemo} from 'react';
import {
  Body,
  Button,
  designSystemToken,
  Headline,
} from '@lightricks/react-design-system';
import Link from '@/components/link';
import styles from './ActionInfoCard.module.scss';

export interface ActionInfoCardProps {
  testID?: string;
  title: string;
  subtitle: string;
  ctaDisabled?: boolean;
  ctaStartIcon?: React.ReactNode;
  ctaText: string;
  ctaLink?: string;
  onCtaClick?: () => void;
}

function ActionInfoCard(props: ActionInfoCardProps) {
  const {
    testID = 'action-info-card',
    title,
    subtitle,
    ctaDisabled = false,
    ctaStartIcon,
    ctaText,
    ctaLink,
    onCtaClick,
  } = props;

  const ctaButton = useMemo(() => {
    const button = (
      <Button
        testID={`${testID}--cta-button`}
        disabled={ctaDisabled}
        startIcon={ctaStartIcon}
        className={styles.ctaButton}
        appearance="neutral"
        mode="elevated"
        size="small"
        onClick={onCtaClick}
      >
        {ctaText}
      </Button>
    );

    if (ctaLink) {
      return (
        <Link to={ctaLink} testID={`${testID}--cta-link`}>
          {button}
        </Link>
      );
    }

    return button;
  }, [testID, ctaDisabled, ctaStartIcon, ctaText, ctaLink, onCtaClick]);

  return (
    <div className={styles.actionInfoCardContainer} data-testid={testID}>
      <div className={styles.textContainer}>
        <Headline size="xs" color={designSystemToken('semantic.fg.primary')}>
          {title}
        </Headline>
        <Body size="sm" color={designSystemToken('semantic.fg.secondary')}>
          {subtitle}
        </Body>
      </div>
      <div className={styles.ctaContainer}>{ctaButton}</div>
    </div>
  );
}

export default ActionInfoCard;
