import React, {useState, useCallback} from 'react';
import {Box, ClickAwayListener, Popper} from '@mui/material';
import {Button, Icon, Input} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {ENTER_KEY} from '@/config/constants';
import {type VettableCreators} from '@/types/vettableCreatorsResponse';
import useVettingHubStore, {vettingHubActions} from '@/stores/vettingHubStore';
import styles from './CreatorSearchBar.module.scss';
import CreatorSearchResults from './CreatorSearchResults';
import NetworkDropdown from './NetworkDropdown';
import useInitialAddedCreatorIds from './useInitialAddedCreatorIds';

export type CreatorSearchBarProps = {
  testID?: string;
  resultsData: VettableCreators | undefined;
  onSubmit: (value: string) => void;
  onCloseResults: () => void;
  isLoading?: boolean;
};

function CreatorSearchBar(props: CreatorSearchBarProps) {
  const {
    testID = 'creator-search-bar',
    resultsData,
    onSubmit,
    onCloseResults,
    isLoading,
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const initialAddedCreators = useInitialAddedCreatorIds(resultsData);
  const searchValue = useVettingHubStore((state) => state.searchValue);
  const searchNetwork = useVettingHubStore((state) => state.searchNetwork);
  const showPopper = React.useMemo(
    () => resultsData !== undefined,
    [resultsData]
  );

  const onClearSearchInput = React.useCallback(() => {
    vettingHubActions.setSearchValue('');
    onCloseResults();
  }, [onCloseResults]);

  const onClickAway = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!inputRef.current) return;

      const {parentElement} = inputRef.current;
      if (parentElement?.contains(e.target as Node)) return;

      onCloseResults();
      inputRef.current?.focus();
    },
    [onCloseResults]
  );

  const onClickSubmit = useCallback(() => {
    onSubmit(searchValue);
  }, [onSubmit, searchValue]);

  const getEndIcon = useCallback(() => {
    return resultsData !== undefined ? (
      <CloseButton onClickClose={onClearSearchInput} />
    ) : (
      <SubmitButton onClickSubmit={onClickSubmit} isLoading={isLoading} />
    );
  }, [isLoading, resultsData, onClickSubmit, onClearSearchInput]);

  const onInputChange = (val: string) => {
    if (showPopper) onCloseResults();
    if (val !== '@') vettingHubActions.setSearchValue(val);
  };

  const [inputFocused, setInputFocused] = useState(false);
  const showAtSymbol = React.useMemo(
    () => Boolean(searchValue || inputFocused),
    [searchValue, inputFocused]
  );

  const startIcon = (
    <>
      <NetworkDropdown onClick={onCloseResults} onClose={onClickSubmit} />
      {showAtSymbol && <Box sx={{ml: '12px', mr: '-10px'}}>@</Box>}
    </>
  );

  const placeholder = React.useMemo(
    () => translate(`creator-handle-placeholder-${searchNetwork}`),
    [searchNetwork]
  );

  return (
    <>
      <div ref={containerRef} data-testid={testID} className={styles.container}>
        <Input
          className={styles.input}
          onFocus={() => {
            setInputFocused(true);
          }}
          onInputBlur={() => {
            setInputFocused(false);
          }}
          startIcon={startIcon}
          value={searchValue}
          onInputChange={onInputChange}
          endIcon={getEndIcon()}
          placeholder={placeholder}
          inputProps={{ref: inputRef, value: searchValue}}
          onKeyPress={({key, charCode}: {key: string; charCode: number}) => {
            if (key === ENTER_KEY.key || charCode === ENTER_KEY.charCode) {
              onClickSubmit();
            }
          }}
        />
      </div>
      <Popper
        disablePortal
        open={showPopper}
        anchorEl={containerRef.current}
        className={styles.popper}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <div
            className={styles.popperContent}
            data-testid={`${testID}-results`}
          >
            <CreatorSearchResults
              data={resultsData}
              initialAddedCreators={initialAddedCreators}
              onClose={onCloseResults}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

type CloseButtonProps = {
  onClickClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

function CloseButton({onClickClose}: CloseButtonProps) {
  return (
    <Button
      testID="close-button"
      appearance="neutral"
      size="medium"
      mode="plain"
      onClick={onClickClose}
      icon={
        <Icon name="Actions-Close-Small" size="medium" appearance="neutral" />
      }
    />
  );
}

type SubmitButtonProps = {onClickSubmit: () => void; isLoading?: boolean};

function SubmitButton({onClickSubmit, isLoading}: SubmitButtonProps) {
  const loadingIcon = '/assets/svg/icon-loading-circle-black.svg';
  const sharedSx = {mr: '-4px', pl: '16px', pr: '12px', zIndex: '100'};
  if (isLoading) {
    return (
      <Box
        component="img"
        src={loadingIcon}
        alt="loading circle icon"
        sx={{pr: '4px'}}
      />
    );
  }
  return (
    <Button
      appearance="neutral"
      size="medium"
      sx={sharedSx}
      mode="filled"
      onClick={onClickSubmit}
      endIcon={
        <Icon
          name="Actions-Arrow-Forward-Small"
          size="medium"
          appearance="inverse"
        />
      }
    >
      {translate('add-creator')}
    </Button>
  );
}

export default CreatorSearchBar;
