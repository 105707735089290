import clsx from 'clsx';
import {MRT_Row} from 'material-react-table';
import React, {useCallback, useMemo} from 'react';
import {useShallow} from 'zustand/react/shallow';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Button,
  designSystemToken,
  Icon,
  Tooltip,
} from '@lightricks/react-design-system';
import {
  BRANDS_SAFETY_ACTION_NAMES,
  FLOW_NAMES,
} from '@/lib/analytics/analyticsConstants';
import {
  updateCreatorBrandSafetyVettingsStatus,
  updateVettingReportStatus,
} from '@/utils/cache-updaters/vettingReportStatus';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import {
  type CreatorBrandSafetyVetting,
  type CreatorBrandSafetyVettingStatus,
} from '@/types/creatorBrandSafetyVetting';
import DropdownButton from '@/components/dropdown-button';
import {Option} from '@/components/dropdown-button/DropdownButton';
import useTrackBrandSafetyCreatorActionEvent from '@/hooks/analytics/use-track-brand-safety-creator-action-event';
import useUpdateVettingStatus from '@/hooks/mutations/brand-safety/use-update-vetting-status';
import {queryKey} from '@/hooks/queries/use-creator-brand-safety-vettings-query';
import {queryKey as vettingReportQueryKey} from '@/hooks/queries/use-vetting-report-query/useVettingReportQuery';
import useBrandId from '@/hooks/use-brand-id';
import useVettingHubStore from '@/stores/vettingHubStore';
import {PLACEHOLDER_ID} from '../constants';
import styles from './StatusCell.module.scss';

const SELECT_OPTIONS: Option[] = [
  {id: 'ready_for_review', label: 'Ready for review'},
  {id: 'approved', label: 'Approved'},
  {id: 'declined', label: 'Declined'},
];

const selectableStatuses: CreatorBrandSafetyVettingStatus[] = [
  'ready_for_review',
  'approved',
  'declined',
];

type StatusCellProps = {
  row: MRT_Row<CreatorBrandSafetyVetting>;
  options?: Option[];
};

function StatusCell({row, options = SELECT_OPTIONS}: StatusCellProps) {
  const {status, statusTooltip} = row.original;
  const updateVettingStatusMutation = useStatusCellMutation(row);
  const selectedTab = useVettingHubStore(
    useShallow((state) => state.activeTab)
  );

  const isPlaceholder = row.original.id === PLACEHOLDER_ID;

  const trackBrandSafetyCreatorActionEvent =
    useTrackBrandSafetyCreatorActionEvent(FLOW_NAMES.BRAND_SAFETY_CREATOR);

  const onChange = (option: {id: string}) => {
    updateVettingStatusMutation.mutateAsync({
      vettingId: row.original.id,
      status: option.id as CreatorBrandSafetyVettingStatus,
    });

    trackBrandSafetyCreatorActionEvent.action({
      brand_safety_screen_name: 'Hub',
      creator_id: row.original.profile?.id,
      action_name: BRANDS_SAFETY_ACTION_NAMES.VETTING_STATUS_CHANGED,
      action_value: option.id,
      tab: selectedTab,
    });
  };

  const dropdownButtonLabel = useMemo(() => {
    return options.find((option) => option.id === status)?.label ?? '';
  }, [options, status]);

  const disabledButtonLabel = useMemo(() => {
    if (status === 'vetting_unavailable') {
      return translate('Vetting Unavailable');
    }
    return translate('queued');
  }, [status]);

  const renderMenuItem = useCallback(
    (option: Option) => (
      <>
        <CircleIcon
          className={clsx(
            styles.circleIcon,
            styles[`circleIcon__${option.id}`]
          )}
        />
        {option.label}
      </>
    ),
    []
  );

  const isEnabled = selectableStatuses.includes(row.original.status);

  return isEnabled ? (
    <DropdownButton
      disabled={isPlaceholder}
      startIcon={<CircleIcon />}
      buttonClassName={clsx(styles.button, styles[`button__${status}`])}
      renderMenuItem={renderMenuItem}
      menuProps={{
        classes: {paper: styles.menu},
        slotProps: {
          root: {
            slotProps: {
              backdrop: {
                invisible: true,
                onClick: (e) => {
                  e.stopPropagation();
                },
              },
            },
          },
        },
      }}
      menuItemProps={{classes: {root: styles.menuItem}}}
      label={dropdownButtonLabel}
      options={options}
      onChange={onChange}
    />
  ) : (
    <Tooltip
      title={statusTooltip}
      arrow
      placement="bottom"
      disableHoverListener={!statusTooltip}
      className={styles.tooltip}
    >
      <div
        className={styles.disabledButtonWrapper}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Button
          disabled
          startIcon={
            statusTooltip ? (
              <Icon
                size="small"
                appearance="neutral"
                name="Actions-Info-Line"
                color={designSystemToken('semantic.fg.disabled')}
              />
            ) : null
          }
          appearance="neutral"
          mode="filled"
          size="small"
          className={clsx(styles.button, styles.buttonInvited)}
        >
          {disabledButtonLabel}
        </Button>
      </div>
    </Tooltip>
  );
}

function useStatusCellMutation(row: StatusCellProps['row']) {
  const brandId = useBrandId();

  return useUpdateVettingStatus({
    onMutate: async ({vettingId, status: newStatus}) => {
      const previousData = updateCreatorBrandSafetyVettingsStatus({
        brandId,
        vettingId,
        status: newStatus,
      });
      updateVettingReportStatus({
        creatorId: row.original.profile?.id,
        reportId: row.original.reportId,
        status: newStatus as CreatorBrandSafetyVettingStatus,
      });

      return previousData;
    },
    onError: (_err, _data, context) => {
      queryClient.setQueryData([queryKey, brandId], context);
    },
    onSettled: () => {
      const {reportId, profile} = row.original;
      queryClient.invalidateQueries({queryKey: [queryKey, brandId]});
      // invalidate the report so it can be re-fetched with the updated status
      queryClient.invalidateQueries({
        queryKey: [vettingReportQueryKey, profile?.id, reportId],
      });
    },
  });
}

export default StatusCell;
