export const CONTENT_SUBMISSION_STATUS = {
  PENDING: 'pending',
  APPROVED: 'accepted',
  ARCHIVED: 'archived',
  REJECTED: 'rejected',
  EDITS_REQUESTED: 'rejected',
};

export const CONTENT_SUBMISSIONS_PER_PAGE = 40;

export const CONTENT_SUBMISSION_STATE_MAP = {
  pending: 'pending,edits_requested',
  accepted: 'accepted',
  archived: 'archived',
};

export enum NETWORKS {
  INSTAGRAM = 'instagram',
  PINTEREST = 'pinterest',
  TIKTOK = 'tiktok',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}

export const uuidRegex =
  '[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}';

export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  GONE: 410,
};

export const ENTER_KEY = {
  key: 'Enter',
  charCode: 13,
};
