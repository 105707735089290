import {useMutation} from '@tanstack/react-query';
import externalRegistrationUpdaters from '@/api/updaters/externalRegistration';

function useCreateExternalRegistration() {
  return useMutation({
    mutationKey: ['externalProfile', 'create'],
    mutationFn: externalRegistrationUpdaters.create,
  });
}

export default useCreateExternalRegistration;
